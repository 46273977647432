.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.HeroBanner {
  height: 5vh;
  font-family: "courier", cursive;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  font-size: 20pt;
  color: white;
}

.HeroBanner :first-child {
  margin-right: auto; /* Push the first image to the left */
  margin-left: 10px;
}

.HeroBanner :last-child {
  margin-left: auto; /* Push the last image to the right */
  margin-right: 10px;
}

.chain-status-container {
  width: 100%; /* Set the container to occupy the full width of its parent */
  display: flex;
  flex-wrap: wrap; /* Allow the child elements to wrap to a new line when required */
  justify-content: center; /* Align the child elements to start from the left */
  align-items: center;
  color: white;
  gap: 12px;
}

.chain-status-container-mobile {
  width: 100%; /* Set the container to occupy the full width of its parent */
  display: flex;
  justify-content: center; /* Align the child elements to start from the left */
  align-items: center;
  color: white;
  gap: 3px;
}

.status-bar {
  display: flex;
  margin-top: 10px;
  border: 1px solid rgb(81, 81, 81);
  margin: 5px 5px;
  border-radius: 8px;
  background-color: rgb(81, 81, 81);
  margin-bottom: 10px;
}

.image-container {
  margin-left: 0px;
  height: 40px;
  width: 45px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-container-mobile {
  margin-left: 0px;
  height: 30px;
  width: 36px;
}

.image-container-mobile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.status-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "courier", cursive;
  font-size: 9pt;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  align-self: stretch; /* Take up full height of parent */
}

.status-image-container img {
  width: 100px; /* Set the width as needed */
  margin-left: 10px; /* Add margin between text and image */
  border-radius: 8px;
}

.right-content {
  flex: 1; /* This makes the right content take the remaining space */
}

.status-boxes {
  display: flex; /* Use flexbox to arrange containers horizontally */
}

.status-boxes-mobile {
  font-family: "courier", cursive;
  font-size: 9pt;
  color: white;
  display: flex;
  flex-direction: column; /* Change the direction to vertical (column) */
}

.container {
  width: 33.33%;
  display: flex;
  align-items: center;
  border: 1px solid rgb(81, 81, 81);
  padding: 10px;
  margin: 3px 3px;
  border-radius: 8px; /* Add this line to round the corners */
  background-color: black;
}

.container-mobile {
  display: flex;
  align-items: center;
  border: 1px solid rgb(81, 81, 81);
  padding: 5px;
  margin: 1px 1px;
  border-radius: 8px; /* Add this line to round the corners */
  background-color: black;
}

.right-content {
  flex: 1; /* This makes the right content take the remaining space */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "freebooter";
  src: url("./fonts/Freebooter.ttf") format("truetype");
  /* Add more src lines for other font file formats if necessary */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "freebooterShadow";
  src: url("./fonts/FreebooterShadow.ttf") format("truetype");
  /* Add more src lines for other font file formats if necessary */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "roboto";
  src: url("./fonts/roboto-latin-400-normal.ttf") format("truetype");
  /* Add more src lines for other font file formats if necessary */
  font-weight: normal;
  font-style: normal;
}

/* Hide MobileComponent by default for desktop devices */
.mobile {
  display: none;
}

/* Hide DesktopComponent by default for mobile devices */
.desktop {
  display: block;
}

.error-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  color: white;
  padding: 20px;
  max-width: 400px;
  width: 90%;
  border-radius: 4px;
}

.error-modal h2 {
  margin-top: 0;
}

.error-modal button {
  background-color: white;
  color: black;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin: 0 auto; /* Add this line to center the button horizontally */
  display: block; /* Add this line to make the button a block-level element */
}

/* Media query for mobile devices with a max-width of 600px */
@media (max-width: 600px) {
  /* Show MobileComponent and hide DesktopComponent on mobile devices */
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}
